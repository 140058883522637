import Skeleton from '@material-ui/lab/Skeleton';
import {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ContentType} from 'recharts/types/component/DefaultLegendContent';
import {getTotalInstitutions} from 'services/institutionQuantityRequest';
import {theme} from 'theme/theme';
import {
  ChartContainer,
  Container,
  Graph,
  LegendContainer,
  LegendItem,
  ScrollInfo,
  SkeletonsContainer,
  TooltipContainer,
  TotalContainer,
} from './styles';

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Sociedades com participação obrigatória</span>
        <span>Sociedades com participação voluntária</span>
      </TooltipContainer>
    );
  }
  return null;
};

const renderLegend = (props: any): ContentType | undefined => {
  const {payload} = props;

  return (
    <LegendContainer>
      {payload.map((entry: any, index: any) => (
        <LegendItem key={`item-${index}`} iconColor={entry.payload.fill}>
          <div></div>
          <p>
            {entry.value === 'instituicoesTransmissoras'
              ? 'Sociedades com participação obrigatória'
              : 'Sociedades com participação voluntária'}
          </p>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};

interface RegisteredInstitutionsProps {
  mobile: boolean;
}
const RegisteredInstitutions: FC<RegisteredInstitutionsProps> = ({mobile}) => {
  const [data, setData] = useState<any | undefined>();
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  useEffect(() => {
    setLoading(true);
    getTotalInstitutions()
      .then(response => {
        if (!response)
          throw new Error(
            'Ocorreu um erro inesperado. Tente novamente mais tarde.',
          );
        if (Object.keys(response.data).length === 0)
          throw new Error(
            'Ocorreu um erro inesperado. Tente novamente mais tarde.',
          );

        // setData([
        //   {
        //     name: 'Instituições',
        //     total: response.data.instituicoesCadastradas,
        //     instituicoesCadastradas:
        //       response.data.instituicoesCadastradas -
        //       response.data.instituicoesTransmissoras,
        //     instituicoesTransmissoras: response.data.instituicoesTransmissoras,
        //   },
        // ])
        setData([
          {
            name: 'Instituições',
            total: 68,
            instituicoesCadastradas: 6,
            instituicoesTransmissoras: 62,
          },
        ]);
        setError({
          status: false,
          message: '',
        });
        setLoading(false);
      })
      .catch(error => {
        setError({
          status: true,
          message: error.message,
        });
        setLoading(false);
        setData(undefined);
      });
  }, []);

  const getDataToExportCSV = (): any => {
    if (data) {
      return data.map(
        ({instituicoesCadastradas, instituicoesTransmissoras, total}: any) => ({
          total_instituições: total,
          instituições_participantes_demais_fases_open_banking:
            instituicoesCadastradas,
          instituicoes_transmissoras_receptoras: instituicoesTransmissoras,
        }),
      );
    }
    return '';
  };

  return (
    <Container>
      {data && !loading && (
        <CSVLink
          data={getDataToExportCSV()}
          filename={'numero-instituicoes-cadastradas.csv'}
          style={{
            display: !mobile ? 'none' : 'block',
            width: 'fit-content',
            marginLeft: 'auto',
          }}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      )}

      <header>
        <div>
          <h1>Número de Sociedades Cadastradas no Diretório</h1>
          <p>
            Número de instituições cadastradas e ativas, última atualização em{' '}
            {[10, 11, 12].includes(new Date().getMonth() + 1)
              ? new Date().getMonth()
              : '0' + (new Date().getMonth() + 1)}
            /2022
          </p>
        </div>

        <CSVLink
          data={getDataToExportCSV()}
          filename={'numero-instituicoes-cadastradas.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      {error.status && (
        <h1 style={{textAlign: 'center', color: 'red'}}>{error.message}</h1>
      )}

      {!data ? (
        <SkeletonsContainer>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </SkeletonsContainer>
      ) : (
        <Graph onScroll={() => setShowScrollInfo(false)}>
          <ChartContainer>
            <ResponsiveContainer minWidth='80%' height={350}>
              <BarChart layout='vertical' data={data}>
                <XAxis
                  type='number'
                  domain={[0, (dataMax: number) => dataMax]}
                />
                <YAxis type='category' dataKey='name' hide />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey='instituicoesTransmissoras'
                  stackId='a'
                  fill={theme.palette.primary.light}
                >
                  <LabelList
                    dataKey='instituicoesTransmissoras'
                    position='middle'
                    fill={theme.palette.common.white}
                  />
                </Bar>
                <Bar
                  dataKey='instituicoesCadastradas'
                  stackId='a'
                  fill={theme.palette.secondary.main}
                >
                  <LabelList
                    dataKey='instituicoesCadastradas'
                    position='middle'
                    fill={theme.palette.common.white}
                  />
                </Bar>
                <Legend content={renderLegend} />
              </BarChart>
            </ResponsiveContainer>
            <TotalContainer>
              <h3>Total de instituições: {Number(data[0].total)}</h3>
            </TotalContainer>
          </ChartContainer>
          <ScrollInfo showScrollInfo={showScrollInfo}>
            <p>
              Pressione e arraste para a esquerda para visualizar o restante do
              gráfico
            </p>
          </ScrollInfo>
        </Graph>
      )}
    </Container>
  );
};
export default RegisteredInstitutions;
