import Skeleton from '@material-ui/lab/Skeleton';
import HistoryDataChanged from 'components/HistoryDataChanged';
import React, {FC, useEffect, useState} from 'react';
import AverageAvailability from '../../components/AverageAvailability';
import AverageResponseTime from '../../components/AverageResponseTime';
import HistoryGraphs from '../../components/HistoryGraphs';
// import HystGraph from '../../components/HystGraph';
import KeyPerformanceMetrics from '../../components/KeyPerformanceMetrics';
import RegisteredInstitutions from '../../components/RegisteredInstitutions';
import RejectedCalls from '../../components/RejectedCalls';
import SuccessfulCalls from '../../components/SuccessfulCalls';
import SysAidCharts from '../../components/SysAidCharts';
import {HistoryParams} from '../../protocols';
import {getHistoryReport} from '../../services/historyReport';
import {getMirrorReport} from '../../services/mirrorReport';
import {Container, SkeletonsContainer, Title} from './styles';

const Dashboard: FC = () => {
  const [orgsData, setOrgsData] = useState<any | undefined>();
  const [historyData, setHistoryData] = useState<HistoryParams | undefined>();
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    getHistoryReport()
      .then(data => {
        if (!data) throw Error('Erro na obtenção dos dados');
        if (Object.keys(data).length === 0)
          throw Error('Erro na obtenção dos dados');
        setHistoryData(data);
        setError({
          status: false,
          message: '',
        });
      })
      .catch(() => {
        setHistoryData(undefined);
        setError({
          status: true,
          message:
            'Ocorreu um erro inesperado. Favor tente novamente mais tarde.',
        });
      });
    getMirrorReport({})
      .then(data => {
        if (!data) throw Error('Erro na obtenção dos dados');
        if (Object.keys(data).length === 0)
          throw Error('Erro na obtenção dos dados');

        setOrgsData(data);
        setError({
          status: false,
          message: '',
        });
      })
      .catch(() => {
        setOrgsData(undefined);
        setError({
          status: true,
          message:
            'Ocorreu um erro inesperado. Favor tente novamente mais tarde.',
        });
      });
  }, []);

  return (
    <>
      {error.status ? (
        <h1 style={{color: 'red', textAlign: 'center'}}>{error.message}</h1>
      ) : (
        <Container>
          <Title id='title-visao-geral'>Visão Geral do Open Insurance</Title>
          {!orgsData ? (
            <SkeletonsContainer>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </SkeletonsContainer>
          ) : (
            <KeyPerformanceMetrics orgsData={orgsData} mobile={isMobile} />
          )}

          <Title id='title-indicadores-consolidados'>
            APIs dos Participantes: Indicadores Consolidados
          </Title>

          {!historyData ? (
            <SkeletonsContainer>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </SkeletonsContainer>
          ) : (
            <HistoryGraphs data={historyData} mobile={isMobile} />
          )}

          <Title id='title-indicadores-instituicoes-participantes'>
            APIs dos Participantes: Indicadores das Instituições Participantes
          </Title>
          {!orgsData && (
            <SkeletonsContainer>
              <Skeleton variant='rect' width={'100%'} height={500} />
              <Skeleton variant='rect' width={'100%'} height={500} />
              <Skeleton variant='rect' width={'100%'} height={500} />
              <Skeleton variant='rect' width={'100%'} height={500} />
              <Skeleton variant='rect' width={'100%'} height={300} />
            </SkeletonsContainer>
          )}

          {!!orgsData && (
            <>
              <SuccessfulCalls orgsData={orgsData} mobile={isMobile} />
              <RejectedCalls orgsData={orgsData} mobile={isMobile} />
              <AverageResponseTime orgsData={orgsData} mobile={isMobile} />
              <AverageAvailability orgsData={orgsData} mobile={isMobile} />
              <HistoryDataChanged />
            </>
          )}

          <Title id='title-diretorio'>
            Elementos de Infraestrutura Compartilhada: Service Desk, Portal e
            Diretório
          </Title>
          <SysAidCharts mobile={isMobile} />
          {/* <HystGraph mobile={isMobile} /> */}
          <RegisteredInstitutions mobile={isMobile} />
        </Container>
      )}
    </>
  );
};

export default Dashboard;
